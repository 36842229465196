@tailwind base;
@tailwind components;
@tailwind utilities;

a {
	color: inherit !important;
}

a:hover {
	color: initial;
}
