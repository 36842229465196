.radio-toolbar {
    margin: 10px;
  }
  
  .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  
  .radio-toolbar label {
      display: inline-block;
      background-color: whitesmoke;
      padding: 2px 6px;
      font-family: sans-serif, Arial;
      font-size: 16px;
      border: 2px solid whitesmoke;
      border-radius: 15px;
      margin-left: 10px;
  }
  
  .radio-toolbar label:hover {
    background-color: whitesmoke;
  }
  
  .radio-toolbar input[type="radio"]:focus + label {
      border: 2px dashed black;
  }
  
  .radio-toolbar input[type="radio"]:checked +label {
      background-color: whitesmoke;
      /* border-color: black; */
  }